<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.monthlyIncomes') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.shop') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="yearModel" :options="yearsDdl" optionLabel="year" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.year') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="monthModel" :options="monthsDdl" optionLabel="month" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.month') }}</label>
                        </span>
                    </div>
                    <div v-show="showGetDataBtn" class="p-col-6 p-md-1 p-text-right"><Button type="button" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <div v-show="showReport" class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.savePdf')" :loading="showDownloadingData" icon="pi pi-download" class="p-button-raised p-button-info" @click="savePdf()" />
                    </div>
                    <div v-show="showReport" class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.saveXls')" :loading="showDownloadingDataXls" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                    </div>
                    <transition-group name="p-message" tag="div" class="p-col-12">
                        <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ $t('alerts.generalError') }}</Message>
                    </transition-group>
                </div>
                <div class="card" v-if="showReportData">
                    <DataTable :value="reportData.Items" ref="datatable" responsiveLayout="scroll">
                        <Column>
                            <template #body="slotProps">
                                <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column :header="$t('reports.incomesAddressHeader')">
                            <template #body="slotProps"
                                ><div>{{ slotProps.data.ShopName }}</div>
                                <div>{{ slotProps.data.ShopAddress }}</div>
                            </template>
                        </Column>
                        <Column field="InAmount" :header="$t('reports.incomesTaxHeader')">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.InAmount) }} </template></Column
                        >
                        <Column field="BonusAmount" :header="$t('reports.incomesBonusHeader')">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.BonusAmount) }} </template></Column
                        >
                        <Column field="OutAmount" :header="$t('reports.incomesWinsHeader')">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutAmount) }} </template></Column
                        >
                        <Column field="JackpotsAmount" :header="$t('reports.incomesSpecialWinsHeader')">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.JackpotsAmount) }} </template></Column
                        >
                        <Column field="ProfitAmount" :header="$t('reports.incomesInHeader')">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.ProfitAmount) }} </template></Column
                        >
                        <Column field="OutTaxAmount" :header="$t('reports.incomesOutTaxHeader')">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutTaxAmount) }} </template></Column
                        >
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../service/BoReportsApi';
import { BoApi } from '../../../service/BoApi';
import { BoSaveReportsApi } from '../../../service/BoSaveReportsApi';
export default {
    name: 'report',
    data() {
        return {
            reportData: [],
            showData: false,
            BoApi: new BoApi(),
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            shops: [],
            shopsModel: { name: 'All', id: 'all' },
            showReportData: false,
            showLoadingData: false,
            showDownloadingData: false,
            showGetDataBtn: false,
            errorKeyCount: 0,
            showError: false,
            showDownloadingDataXls: false,
            yearModel: '',
            monthModel: '',
            yearsDdl: [],
            monthsDdl: [],
        };
    },
    beforeMount() {
        let crtDate = new Date();
        let crtYear = crtDate.getFullYear();
        var startYear = this.getReportsStartYear();
        for (let i = startYear; i <= crtYear; i++) {
            var yearObj = { year: i, id: i };
            this.yearsDdl.push(yearObj);
            if (i === crtYear) {
                this.yearModel = yearObj;
            }
        }
        let crtMonth = crtDate.getMonth() + 1;
        for (let i = 1; i <= 12; i++) {
            this.monthsDdl.push({ month: this.$t('months.' + i), id: i });
        }
        this.monthModel = { month: this.$t('months.' + crtMonth), id: crtMonth };
        this.getShops();
    },
    watch: {
        shopsModel() {
            if (this.notNullOrEmptyObject(this.shopsModel)) {
                if (this.shopsModel.id == 'all') {
                    this.showGetDataBtn = false;
                } else {
                    this.showGetDataBtn = true;
                }
            } else {
                this.shopsModel = { name: 'All', id: 'all' };
                this.showGetDataBtn = false;
            }
        },
    },
    methods: {
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                    this.shops.unshift(this.shopsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                });
        },
        showReport() {
            this.showError = false;
            this.showLoadingData = true;
            this.BoReportsApi.getMonthlyIncomes(this.shopsModel.id, this.monthModel.id, this.yearModel.id)
                .then((response) => {
                    this.showLoadingData = false;
                    this.reportData = response.data;
                    this.showReportData = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                });
        },
        savePdf() {
            this.showError = false;
            this.showDownloadingData = true;
            this.BoSaveReportsApi.saveMonthlyIncomes(this.shopsModel.id, this.monthModel.id, this.yearModel.id)
                .then((response) => {
                    this.showDownloadingData = false;
                    let fileName = 'MonthlyIncomes_' + this.shopsModel.name + '_' + this.yearModel.year + '_' + this.monthModel.month + +'.pdf';
                    let blob = new Blob([response.data], { type: 'application/pdf' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showDownloadingData = false;
                    this.showError = true;
                    this.errorKeyCount++;
                });
        },
        saveXls() {
            this.showDownloadingDataXls = true;
            this.BoSaveReportsApi.saveMonthlyIncomes(this.shopsModel.id, this.monthModel.id, this.yearModel.id)
                .then((response) => {
                    this.showDownloadingDataXls = false;
                    let blob = new Blob([response.data], { type: 'application/xls' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'MonthlyIncomes_' + this.shopsModel.name + '_' + this.yearModel.year + '_' + this.monthModel.month + +'.xlsx';
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showDownloadingDataXls = false;
                    this.showError = true;
                    this.errorKeyCount++;
                });
        },
    },
};
</script>

